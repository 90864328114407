// Form.js

import { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import './StaffSignIn.css'; // Assuming you'll have a separate CSS file for SignInPage

import axios from 'axios';

const StaffSignIn = () => {
    const history = useNavigate();
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    // useRef to keep track of whether the component is mounted or not to prevent state updates on an unmounted component.
    const isMounted = useRef(true);
    const [zoomLevel, setZoomLevel] = useState(1); // 1 means 100% or default size
    const [fadeIn, setFadeIn] = useState(false);


    const [formattedTime, setFormattedTime] = useState('');
    const [isStep1Valid, setIsStep1Valid] = useState(false);
    const [isStep2Valid, setIsStep2Valid] = useState(false);

    const [domain, setDomain] = useState('@gmail.com');
    const [step, setStep] = useState(1);


    const [fullName, setFullName] = useState('');
    const [LocationOfWork, setLocationOfWork] = useState('');
    const [TheDate, setTheDate] = useState('');
    const minScale = 0.5;
    const maxScale = 1.3;
    useEffect(() => {
        if (step === 2 ) {
            axios.post('https://trackmyaccess.site/api/saveStaffData', {
              fullName,
                TheDate,
                selectedOptions,
                formattedTime
            });
        }
    }, [step]);
    const toggleOption = (option) => {
        let updatedOptions;
        
        if (selectedOptions.includes(option)) {
            updatedOptions = selectedOptions.filter(o => o !== option);
        } else {
            updatedOptions = [...selectedOptions, option];
        }
        setSelectedOptions(updatedOptions);
    
        // Validation logic
     //   if (updatedOptions.length > 0) {
         //   setIsStep2Valid(true);
       // } else {
       //     setIsStep2Valid(false);
      //  }
    };
    
    
    const handleZoomIn = () => {

        setZoomLevel(prev => Math.min(prev + 0.1, 2)); // max zoom: 200%
    };

    const handleZoomOut = () => {
        setZoomLevel(prev => Math.max(prev - 0.1, 1)); // min zoom: 100% or default size
    };

    useEffect(() => {
        if (step === 2) {
            // Start the fade out after 5 seconds
            const timeout = setTimeout(() => {
                setIsFadingOut(true);
    
                // Navigate to Home after the animation duration (2 seconds as defined in the CSS)
                const navigateTimeout = setTimeout(() => {
                    history("/"); // Navigate to the home page
                }, 2000);
    
                return () => clearTimeout(navigateTimeout); // Cleanup the navigate timeout if the component gets unmounted
            }, 5000);
    
            return () => clearTimeout(timeout); // Cleanup the timeout if the component gets unmounted
        }
    }, [step, history]);


    useEffect(() => {
        const test = new Date();

        const nzTime = new Intl.DateTimeFormat('en-NZ', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Pacific/Auckland'
        }).format(test);
        
        setFormattedTime(nzTime);
    }, []); // The empty dependency array ensures this runs only once when the component mounts
   // This useEffect is for setting the initial date.
useEffect(() => {
    if (step === 1) {
        // Get the current date and time
        const now = new Date();
        
        // Adjust for New Zealand's time zone (approximate, doesn't consider DST)
        const offset = now.getTimezoneOffset() + (12 * 60); // local offset + NZ offset
        now.setMinutes(now.getMinutes() + offset);

        // Convert to yyyy-MM-dd for setting to the input
        const adjustedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;

        // Set the value to TheDate state
        setTheDate(adjustedDate);
    }
}, [step]);

// This useEffect handles the showExtraOptions based on visitPurpose.

useEffect(() => {
    validateStep1();
}, [fullName, selectedOptions, TheDate]);



const validateStep1 = () => {
    if (fullName && selectedOptions.length > 0 && TheDate) {
        setIsStep1Valid(true);
    } else {
        setIsStep1Valid(false);
    }
};

const validateStep2 = () => {
    if (selectedOptions.length > 0) {
        setIsStep2Valid(true);
        return true;  // valid
    } else {
        setIsStep2Valid(false);
        return false;  // not valid
    }
};
const [names, setNames] = useState([]);
useEffect(() => {
    fetch('https://trackmyaccess.site/api/names') // Assuming the names.txt file is in the public folder of your React app
      .then(response => response.text())
      .then(data => {
        const extractedNames = data.match(/"([^"]+)"/g);
        if (extractedNames) {
          const cleanedNames = extractedNames.map(name => name.replace(/"/g, ''));
          setNames(cleanedNames);
        }
      })
      .catch(error => console.error('Error fetching names:', error));
  }, []);
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    const atIndex = emailValue.indexOf('@');

    if (domain === 'custom') {
      setFullName(emailValue);
    } else {
      if (atIndex !== -1) {
        setFullName(emailValue.substring(0, atIndex));
      } else {
        setFullName(emailValue);
      }
    }
  };
  const handleDomainChange = (e) => {
    const selectedDomain = e.target.value;

    // If selecting the default option, unset the email
    if (selectedDomain === 'custom') {
      setFullName('');
    }

    setDomain(selectedDomain);

    if (selectedDomain !== 'custom' && names.indexOf('@') === -1) {
      setFullName(prevEmail => prevEmail + selectedDomain);
    }
  };


    return (
       <div className={`form-container ${isFadingOut ? 'fade-out' : ''}`}>
             <div className="zoom-controls">
                <button onClick={handleZoomIn}>+</button>
                <button onClick={handleZoomOut}>-</button>
      
            </div>
        <div   className="tabs-container" >
            <div className={`tab ${step === 1 ? 'tab2 active' : ''}`}>Staff Sign In</div>
        
            <div className={`tab ${step === 2 ? 'tab2 active' : ''}`}>Completed</div>
        </div>

        {step === 1 && (
            <div style={{ transform: `scale(${Math.min(Math.max(zoomLevel, minScale), maxScale)})` }}  className="container">
             
           
             <div className="email-container2">
     

      <select id="emailSelector" onChange={handleEmailChange}>
        {names.map((name, index) => (
          <option key={index} value={name}>
            {name}
          </option>
        ))}
        <option value="custom">Name of Staff</option>
      </select>
    </div>
                <div className="date">
                    <input type="date" id="dateInput" value={TheDate} onChange={(e) => setTheDate(e.target.value)}/>
                </div>

                <div className="radio-container">
          <h4>Location of Work</h4>
                <div className="radio-item">
            <input type="checkbox" 
                   id="Option2" 
                   name="extraOptions" 
                   checked={selectedOptions.includes('Pursuit Office')} 
                   onChange={() => toggleOption('Pursuit Office')} />
            <label htmlFor="Option2">Pursuit Office</label>
          </div>
          <div className="radio-item">
            <input type="checkbox" 
                   id="Option1" 
                   name="extraOptions" 
                   checked={selectedOptions.includes('Work from Home')}
                   onChange={() => toggleOption('Work from Home')} />
            <label htmlFor="Option1">Work from Home</label>
          </div>


      
          {/* Add more options as needed */}
        </div>
            </div>
        )}

{step === 2 && (

<div className="container">
<h1 className="header">Successfully Signed In.</h1>

<div>


</div>


</div>
)}


<div className="navigation-buttons">

    {step === 1 && !isStep1Valid && <button id="test" disabled>Sign In</button>}
    {step === 1 && isStep1Valid && <button id="test2" onClick={() => setStep(step + 1)}>Sign In</button>}


</div>
        </div>
    );
};


export default StaffSignIn;