import React from 'react';
import { Navigate } from 'react-router-dom';

const Protected = ({ component: Component, ...props }) => {
    const token = localStorage.getItem('token');
    if (token) {
        return <Component {...props} />;
    } else {
        return <Navigate to="/login" replace />;
    }
};

export default Protected;
