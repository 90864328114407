import { useState, useEffect }  from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';
const Dashboard = () => {
    const [data, setData] = useState([]);
    const [staffdata, setStaffData] = useState([]);
    const [fadeIn, setFadeIn] = useState(false);
    const [message, setMessage] = useState(null);
    const [names, setNames] = useState([]);
    const [newName, setNewName] = useState('');
    const [isTableVisible, setTableVisibility] = useState(false);
    const [isTableVisible2, setTableVisibility2] = useState(false);
    const [isTableVisible3, setTableVisibility3] = useState(false);
    
    const handleToggleTable = () => {
      setTableVisibility(!isTableVisible);
    };
    const handleToggleTable2 = () => {
      setTableVisibility2(!isTableVisible2);
    };
    const handleToggleTable3 = () => {
      setTableVisibility3(!isTableVisible3);
    };
    const fetchNames = async () => {
      try {
        const response = await axios.get('https://trackmyaccess.site/api/get-names'); // Replace this with your GET endpoint to fetch names
        setNames(response.data.names);
      } catch (error) {
        console.error("Error fetching names:", error);
      }
    };
    const addName = async () => {
      try {
        await axios.post('https://trackmyaccess.site/api/add-name', { name: newName });
        setNewName(''); // Clear the input field after adding the name
        fetchNames(); // Fetch the updated names list after adding
        fetch('https://trackmyaccess.site/api/names')
        .then(response => response.text())
        .then(data => {
          const extractedNames = data.match(/"([^"]+)"/g);
          if (extractedNames) {
            const cleanedNames = extractedNames.map(name => name.replace(/"/g, ''));
            setNames(cleanedNames);
          }
        })
      } catch (error) {
        console.error("Error adding name:", error);
      }
    };
  
    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);
        }, 100); // Delay to ensure page render has occurred before starting animation.
    }, []);

    const handleExportData = () => {
        window.location.href = 'https://trackmyaccess.site/api/downloadData';
    };
    const handleExportStaffData = () => {
      window.location.href = 'https://trackmyaccess.site/api/downloadStaffData';
  };
  const [deleteConfirmation, setDeleteConfirmation] = useState({});

  useEffect(() => {
    // Initialize deleteConfirmation with the same length as the names array
    setDeleteConfirmation(new Array(names.length).fill(false));
  }, [names]);

  useEffect(() => {
    // Your API call logic to fetch names
    fetch('https://trackmyaccess.site/api/names')
      .then(response => response.text())
      .then(data => {
        const extractedNames = data.match(/"([^"]+)"/g);
        if (extractedNames) {
          const cleanedNames = extractedNames.map(name => name.replace(/"/g, ''));
          setNames(cleanedNames);
        }
      })
      .catch(error => console.error('Error fetching names:', error));
  }, []);

  const toggleDeleteConfirmation = (index) => {
    const updatedConfirmation = [...deleteConfirmation];
    updatedConfirmation[index] = !updatedConfirmation[index];
    setDeleteConfirmation(updatedConfirmation);
  };
  const deleteName = async (name, index) => {
    try {
      const response = await axios.get(`https://trackmyaccess.site/api/delete-name/${name}`);
      setNames(response.data.names);
      const updatedConfirmation = [...deleteConfirmation];
      updatedConfirmation[index] = false; // Reset confirmation after deletion
      setDeleteConfirmation(updatedConfirmation);
    } catch (error) {
      console.error("Error deleting name:", error);
    }
  };

    const fetchData = () => {
        axios.get('https://trackmyaccess.site/api/getAllData')
            .then(response => {
                setData(response.data);
                console.log(response.data);
            })
            .catch(error => {
                console.error("Error fetching data", error);
            });
    }

    const fetchStaffData = () => {
      axios.get('https://trackmyaccess.site/api/getAllStaffData')
          .then(response => {
              setStaffData(response.data);
              console.log(response.data);
          })
          .catch(error => {
              console.error("Error fetching data", error);
          });
  }

 
    const navigate = useNavigate();
    const handleGoBack = () => {
    
      // Logic to go back
      navigate(-1); // If using useHistory hook
      //navigate(-1); // If using useNavigate hook from React Router v6
  };
  const dashboardStyle = {
    marginTop: '-130px', // Different margin for the dashboard
   
  };
    useEffect(() => {
        fetchData();  // Using the refactored function here
    }, []); // empty dependency array means this effect runs once when the component mounts
    useEffect(() => {
      fetchStaffData();  // Using the refactored function here
  }, []); // empty dependency array means this effect runs once when the component mounts
  useEffect(() => {
    fetch('https://trackmyaccess.site/api/names')
      .then(response => response.text())
      .then(data => {
        const extractedNames = data.match(/"([^"]+)"/g);
        if (extractedNames) {
          const cleanedNames = extractedNames.map(name => name.replace(/"/g, ''));
          setNames(cleanedNames);
        }
      })
      .catch(error => console.error('Error fetching names:', error));
  }, []);
    return (
        <div style={dashboardStyle} className={`center-contents ${fadeIn ? 'fade-in' : ''}`}>
     <div className="dashboard-container">
      <div style={{ display: 'inline-block' }}>
        <h1 className="h1" style={{ display: 'inline', marginRight: '20px' }}>Pursuit Sign-In Sheet for Visitors</h1>
        <button className="ExportButton" onClick={handleExportData} style={{ display: 'inline' }}>Export Visitor Data</button>
      </div>

      {message && (
        <div className="message">
          {message}
        </div>
      )}

      <button onClick={handleToggleTable}>
        {isTableVisible ? 'Hide Visitor Table' : 'Show Visitor Table'}
      </button>

      {isTableVisible && data.length > 0 ? (
        <div className="table-responsive page-centre">
          <table className="custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>FULL NAME</th>
                <th>Phone Number</th>
                <th>Date</th>
                <th>Time of Sign In</th>
                <th>Email</th>
                <th>Purpose</th>
                <th>Time of Sign Out</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.fullName}</td>
                  <td>{row.phoneNumber}</td>
                  <td>{row.date}</td>
                  <td>{row.signInTime}</td>
                  <td>{row.email}</td>
                  <td>{row.purpose}</td>
                  <td>{row.signOutTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-data">
         Table is hidden or no data available.
        </div>
      )}
    </div>
      
          <div className="dashboard-container">
      <div style={{ display: 'inline-block' }}>
        <h1 className="h1" style={{ display: 'inline', marginRight: '20px' }}>Pursuit Sign-In Sheet for Staff</h1>
        <button className="ExportButton" onClick={handleExportStaffData} style={{ display: 'inline' }}>Export Staff Data</button>
      </div>

      {message && (
        <div className="message">
          {message}
        </div>
      )}

      <button onClick={handleToggleTable2}>{isTableVisible2 ? 'Hide Table' : 'Show Table'}</button>

      {isTableVisible2 && staffdata.length > 0 ? (
        <div className="table-responsive page-centre">
          <table className="custom-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>FULL NAME</th>
                <th>Date</th>
                <th>Location of Work</th>
                <th>Time of Sign In</th>
                <th>Time of Sign Out</th>
              </tr>
            </thead>
            <tbody>
              {staffdata.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>{row.fullName}</td>
                  <td>{row.date}</td>
                  <td>{row.signInTime}</td>
                  <td>{row.location}</td>
                  <td>{row.signOutTime}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-data">
          Table is hidden or no data available.
        </div>
      )}
    </div>
          <div className="dashboard-container">
          <h1 className="h1" style={{ marginBottom: '10px' }}>Pursuit Staff</h1>
      <div style={{ marginTop: '2px', textAlign: 'center', display: 'inline-block' }}>
      
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Name"
            style={{ width: '150px', marginRight: '10px', textAlign: 'center' }}
          />
          <button onClick={addName}>Add Name</button>
        </div>
      </div>

      <button onClick={handleToggleTable3}>{isTableVisible3 ? 'Hide Table' : 'Show Table'}</button>

      {names.length > 0 ? (
        isTableVisible3 ? (
          <div className="table-responsive page-centre">
            <table className="custom-table">
              <thead>
                <tr>
                  <th>FULL NAME</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {names.map((name, index) => (
                  <tr key={index}>
                    <td>{name}</td>
                    <td>
                      {deleteConfirmation[index] ? (
                        <button
                          onClick={() => deleteName(name, index)}
                          style={{
                            backgroundColor: 'red',
                            color: 'white',
                          }}
                        >
                          Confirm Deletion?
                        </button>
                      ) : (
                        <button
                          onClick={() => toggleDeleteConfirmation(index)}
                          style={{
                            backgroundColor: '#007BFF',
                            color: 'white',
                          }}
                        >
                          Delete
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="no-data">
           Table is hidden or no data available.
          </div>
        )
      ) : (
        <div className="no-data">
          No names available.
        </div>
      )}
    </div>
          <div className="actions">
                    <button type="button" onClick={handleGoBack}>Go Back</button>
   
                </div>
        </div>
      );
      
      
    
}

export default Dashboard;
