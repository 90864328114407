// Form.js

import { useState, useEffect, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import './Form.css'; // Assuming you'll have a separate CSS file for SignInPage

import axios from 'axios';

const Form = () => {
    const history = useNavigate();
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    // useRef to keep track of whether the component is mounted or not to prevent state updates on an unmounted component.
    const isMounted = useRef(true);
    const [zoomLevel, setZoomLevel] = useState(1); // 1 means 100% or default size
    const [fadeIn, setFadeIn] = useState(false);


    const [formattedTime, setFormattedTime] = useState('');
    const [isStep1Valid, setIsStep1Valid] = useState(false);
    const [isStep2Valid, setIsStep2Valid] = useState(false);
    const [email, setEmail] = useState('');
    const [domain, setDomain] = useState('@gmail.com');
    const [step, setStep] = useState(1);


    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [TheDate, setTheDate] = useState('');
    const minScale = 0.5;
    const maxScale = 1.3;
    useEffect(() => {
        if (step === 3) {
            axios.post('https://trackmyaccess.site/api/saveData', {
                fullName,
                phoneNumber,
                TheDate,
                formattedTime,
                email,
                domain,
                selectedOptions
            });
        }
    }, [step]);
    const toggleOption = (option) => {
        let updatedOptions;
        
        if (selectedOptions.includes(option)) {
            updatedOptions = selectedOptions.filter(o => o !== option);
        } else {
            updatedOptions = [...selectedOptions, option];
        }
        setSelectedOptions(updatedOptions);
    
        // Validation logic
        if (updatedOptions.length > 0) {
            setIsStep2Valid(true);
        } else {
            setIsStep2Valid(false);
        }
    };
    
    
    const handleZoomIn = () => {

        setZoomLevel(prev => Math.min(prev + 0.1, 2)); // max zoom: 200%
    };

    const handleZoomOut = () => {
        setZoomLevel(prev => Math.max(prev - 0.1, 1)); // min zoom: 100% or default size
    };

    useEffect(() => {
        if (step === 3) {
            // Start the fade out after 5 seconds
            const timeout = setTimeout(() => {
                setIsFadingOut(true);
    
                // Navigate to Home after the animation duration (2 seconds as defined in the CSS)
                const navigateTimeout = setTimeout(() => {
                    history("/"); // Navigate to the home page
                }, 2000);
    
                return () => clearTimeout(navigateTimeout); // Cleanup the navigate timeout if the component gets unmounted
            }, 5000);
    
            return () => clearTimeout(timeout); // Cleanup the timeout if the component gets unmounted
        }
    }, [step, history]);


    useEffect(() => {
        const test = new Date();

        const nzTime = new Intl.DateTimeFormat('en-NZ', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: 'Pacific/Auckland'
        }).format(test);
        
        setFormattedTime(nzTime);
    }, []); // The empty dependency array ensures this runs only once when the component mounts
   // This useEffect is for setting the initial date.
useEffect(() => {
    if (step === 1) {
        // Get the current date and time
        const now = new Date();
        
        // Adjust for New Zealand's time zone (approximate, doesn't consider DST)
        const offset = now.getTimezoneOffset() + (12 * 60); // local offset + NZ offset
        now.setMinutes(now.getMinutes() + offset);

        // Convert to yyyy-MM-dd for setting to the input
        const adjustedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;

        // Set the value to TheDate state
        setTheDate(adjustedDate);
    }
}, [step]);

// This useEffect handles the showExtraOptions based on visitPurpose.

useEffect(() => {
    validateStep1();
}, [fullName, email, phoneNumber, TheDate]);



const validateStep1 = () => {
    if (fullName && email && phoneNumber && TheDate) {
        setIsStep1Valid(true);
    } else {
        setIsStep1Valid(false);
    }
};

const validateStep2 = () => {
    if (selectedOptions.length > 0) {
        setIsStep2Valid(true);
        return true;  // valid
    } else {
        setIsStep2Valid(false);
        return false;  // not valid
    }
};


const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    const atIndex = emailValue.indexOf('@');

    // If '@' is present and the domain is 'custom', let the user input the whole email.
    if (domain === 'custom') {
        setEmail(emailValue);
    } else {
        // If '@' is present in the input value, only take the part before it.
        if (atIndex !== -1) {
            setEmail(emailValue.substring(0, atIndex));
        } else {
            setEmail(emailValue);
        }
    }
}



    return (
       <div className={`form-container ${isFadingOut ? 'fade-out' : ''}`}>
             <div className="zoom-controls">
                <button onClick={handleZoomIn}>+</button>
                <button onClick={handleZoomOut}>-</button>
      
            </div>
        <div   className="tabs-container" >
            <div className={`tab ${step === 1 ? 'tab2 active' : ''}`}>General Information</div>
            <div className={`tab ${step === 2 ? 'tab2 active' : ''}`}>Appointment</div>
            <div className={`tab ${step === 3 ? 'tab2 active' : ''}`}>Completed</div>
        </div>

        {step === 1 && (
            <div style={{ transform: `scale(${Math.min(Math.max(zoomLevel, minScale), maxScale)})` }}  className="container">
             
                <div className="date">
                <input type="text" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                </div>
                <div className="email-container">
                <input type="text" placeholder="Email" value={email} onChange={handleEmailChange} />

                <select id="emailSelector" onChange={(e) => {
    setDomain(e.target.value);
    if (e.target.value !== 'custom' && email.indexOf('@') === -1) {
        setEmail(prevEmail => prevEmail + e.target.value);
    }
}}>
    <option value="@gmail.com">@gmail.com</option>
    <option value="@yahoo.com">@yahoo.com</option>
    <option value="@hotmail.com">@hotmail.com</option>
    <option value="@outlook.com">@outlook.com</option>
    <option value="@mail.com">@mail.com</option>
    <option value="custom">Custom Provider</option>
</select>
                </div>
                <div className="date">
                <input type="text" placeholder="Phone Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />

                </div>
                <div className="date">
                    <input type="date" id="dateInput" value={TheDate} onChange={(e) => setTheDate(e.target.value)}/>
                </div>
            </div>
        )}

{step === 2 && (
  <div className="container">
    <h1 className="header">Purpose of Visit</h1>
    <div className="radio-container">
      
      <div className="radio-item">
        <input type="checkbox" 
               id="Meeting" 
               name="visitPurpose" 
               checked={selectedOptions.includes('Meeting')} 
               onChange={() => toggleOption('Meeting')} />
        <label htmlFor="Meeting">Meeting</label>
      </div>

      <div className="radio-item">
        <input type="checkbox" 
               id="TeAraMatihiko" 
               name="visitPurpose" 
               checked={selectedOptions.includes('Te Ara Matihiko')} 
               onChange={() => toggleOption('Te Ara Matihiko')} />
        <label htmlFor="TeAraMatihiko">Te Ara Matihiko</label>
      </div>

      <div className="radio-item">
        <input type="checkbox" 
               id="Korowai" 
               name="visitPurpose" 
               checked={selectedOptions.includes('Whiria te tangata')} 
               onChange={() => toggleOption('Whiria te tangata')} />
        <label htmlFor="Korowai">Whiria te tangata</label>
      </div>

      <div className="radio-item">
        <input type="checkbox" 
               id="Information1" 
               name="visitPurpose" 
               checked={selectedOptions.includes('Information')} 
               onChange={() => toggleOption('Information')} />
        <label htmlFor="Information1">Information</label>
      </div>

    

        <div className="radio-container">
          
          <div className="radio-item">
            <input type="checkbox" 
                   id="Option1" 
                   name="extraOptions" 
                   checked={selectedOptions.includes('CV')}
                   onChange={() => toggleOption('CV')} />
            <label htmlFor="Option1">CV</label>
          </div>

          <div className="radio-item">
            <input type="checkbox" 
                   id="Option2" 
                   name="extraOptions" 
                   checked={selectedOptions.includes('Cover Letter')} 
                   onChange={() => toggleOption('Cover Letter')} />
            <label htmlFor="Option2">Cover Letter</label>
          </div>

          <div className="radio-item">
            <input type="checkbox" 
                   id="Option3" 
                   name="extraOptions" 
                   checked={selectedOptions.includes('Dressing')} 
                   onChange={() => toggleOption('Dressing')} />
            <label htmlFor="Option3">Dressing</label>
          </div>

          <div className="radio-item">
            <input type="checkbox" 
                   id="Option4" 
                   name="extraOptions" 
                   checked={selectedOptions.includes('Interview Preparation')} 
                   onChange={() => toggleOption('Interview Preparation')} />
            <label htmlFor="Option4">Interview Preparation</label>
          </div>
          
          {/* Add more options as needed */}
        </div>
      
      
    </div>
  </div>
)}
{step === 3 && (

    <div className="container">
        <h1 className="header">Successfully Signed In.</h1>

        <div>
   

        </div>

        
    </div>
)}

<div className="navigation-buttons">
    {step > 1 && step !== 3 && <button onClick={() => setStep(step - 1)}>Previous</button>}
    {step === 1 && !isStep1Valid && <button id="test" disabled>Next</button>}
    {step === 1 && isStep1Valid && <button id="test2" onClick={() => setStep(step + 1)}>Next</button>}
    {step === 2 && !isStep2Valid && <button disabled={!isStep2Valid}>Submit</button>}
    {step === 2 && isStep2Valid && <button onClick={() => setStep(step + 1)}>Submit</button>}
</div>
        </div>
    );
};


export default Form;