import React, { useRef } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';



import './App.css';
import Form from './components/Form/Form'; // Make sure this path is correct
import StaffSignInForm from './components/StaffSignIn/StaffSignIn'; // Make sure this path is correct
import StaffSignOutForm from './components/StaffSignOut/StaffSignOut'; // Make sure this path is correct
import SignOut from './components/SignOut/SignOut'; // Make sure this path is correct
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import ProtectedRoute from './components/Protected/Protected';
import Dashboard from './components/Dashboard/Dashboard';
function App() {
  const headerRef = useRef(null);
  const footerRef = useRef(null);

  return (
    <Router>
      <div className="App">
        <Header ref={headerRef} />
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home headerRef={headerRef} footerRef={footerRef} />} />
            <Route path="/signin" element={<Form />} />
            <Route path="/staffsignin" element={<StaffSignInForm />} />
            <Route path="/staffout" element={<StaffSignOutForm />} />
            <Route path="/signout" element={<SignOut />} />
            <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
        <Footer ref={footerRef} />
      </div>
    </Router>
  );
}


export default App;
