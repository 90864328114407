import React from 'react';
import footerImage from '../../images/footer.png';

const Footer = () => {
    return (
        <footer>
            <img src={footerImage} alt="Footer Logo" style={{ width: '100%', height: '50px' }} />
        </footer>
    );
};

export default Footer;
