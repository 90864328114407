// Home.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // Assuming you'll have a separate CSS file for Home
import logo from '../../images/tranpursuit.png';


const Home = () => {
    const navigate = useNavigate();
    const [zoomLevel, setZoomLevel] = useState(1); // 1 means 100% or default size
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);
        }, 100); // Delay to ensure page render has occurred before starting animation.
    }, []);

    const handleFormClick = () => {
        navigate('/signin'); // Navigating to the SignInPage when button is clicked
    };
    const handleFormClickSignOut = () => {
        navigate('/signout'); // Navigating to the SignInPage when button is clicked
    };
    const handleFormClickLogin = () => {
        navigate('/login'); // Navigating to the SignInPage when button is clicked
    };
    const handleStaffSignIn = () => {
        navigate('/staffsignin'); // Navigating to the SignInPage when button is clicked
    };
    const handleStaffSignOut = () => {
        navigate('/staffout'); // Navigating to the SignInPage when button is clicked
    };
    const handleZoomIn = () => {

        setZoomLevel(prev => Math.min(prev + 0.1, 1.22)); // max zoom: 200%
    };

    const handleZoomOut = () => {
        setZoomLevel(prev => Math.max(prev - 0.1, 1)); // min zoom: 100% or default size
    };
    return (
        <div className={`home-container ${fadeIn ? 'fade-in' : ''}`} style={{ fontSize: `${zoomLevel}em` }}>
            <div className="logo-container">
                <img src={logo} alt="Putsuit Logo" className="img" />
            </div>
    
            <div className="button-container">
                <button className="signin-button" onClick={handleFormClick}>Sign In</button>
                <button className="signout-button" onClick={handleFormClickSignOut}>Sign Out</button>
            </div>
            

    
            <div className="zoom-controls">
                <button onClick={handleZoomIn}>+</button>
                <button onClick={handleZoomOut}>-</button>
      
            </div>
            <div className="zoom-controls2">
                <button onClick={handleStaffSignIn}>Staff Sign In</button>
                <button onClick={handleStaffSignOut}>Staff Sign Out</button>
                <button onClick={handleFormClickLogin}>Admin Login</button>
      
            </div>
           
        </div>
    );
    
};

export default Home;
