import { useState, useEffect } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './Login.css';


const SignOut = () => {

    useEffect(() => {
        // This function runs when the component mounts
        const elements = document.querySelectorAll('.content-container');
        elements.forEach(el => {
            el.style.marginTop = '0px'; // Override the margin-top
        });
  
        // Optional: This function runs when the component unmounts
        return () => {
            elements.forEach(el => {
                el.style.marginTop = '170px'; // Revert the margin-top (if necessary)
            });
        };
    }, []); // Empty dependency array means this useEffect runs once when component mounts


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('https://trackmyaccess.site/api/login', { username, password });

            if (response.data.success) {
                setErrorMessage("Success!");
                // Store the token in localStorage
                localStorage.setItem('token', response.data.token);
                // Redirect to the dashboard
                navigate('/dashboard');
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            console.error("Error during login", error);
            setErrorMessage("Server error. Please try again later.");
        }
    }

    const handleGoBack = () => {
    
        // Logic to go back
        navigate(-1); // If using useHistory hook
        //navigate(-1); // If using useNavigate hook from React Router v6
    };
    return (
        <div className="center-contents">
        <div className="login-container">
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <div className="input-group">
                    <label htmlFor="username">Username</label>
                    <input
                        value={username}
                        type="text"
                        id="username"
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="password">Password</label>
                    <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        id="password"
                        placeholder="Password"
                        required
                    />
                </div>
                <div className="error-message">{errorMessage}</div>
                <div className="actions">
                    <button type="button" onClick={handleGoBack}>Go Back</button>
                    <button type="submit">Login</button>
                </div>
            </form>
        </div>
    </div>
);
};

export default SignOut;
