import React from 'react';
import headerImage from '../../images/header.png';


const Header = React.forwardRef((props, ref) => {
    return (
        <header ref={ref}>
   <img src={headerImage} alt="Header Logo" style={{ width: '100%', height: '50px' }} />
        </header>
    );
});

export default Header;
