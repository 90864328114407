import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './SignOut.css';
const SignOut = () => {
    const [data, setData] = useState([]);
    const [message, setMessage] = useState(null);
    const [fadeOut, setFadeOut] = useState(false); // New state to handle the fade out effect
    const [fadeIn, setFadeIn] = useState(false);
    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.6/css/bootstrap.min.css';
        document.head.appendChild(link);
    
        return () => {
          document.head.removeChild(link);
        };
      }, []);
    useEffect(() => {
        setTimeout(() => {
            setFadeIn(true);
        }, 100); // Delay to ensure page render has occurred before starting animation.
    }, []);

    const history = useNavigate(); // Hook from react-router-dom to handle programmatic navigation

    // Refactored function to fetch data
    const fetchData = () => {
        axios.get('https://trackmyaccess.site/api/getData')
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error("Error fetching data", error);
            });
    }

    useEffect(() => {
        fetchData();  // Using the refactored function here
    }, []); // empty dependency array means this effect runs once when the component mounts
    useEffect(() => {
      // This function runs when the component mounts
      const elements = document.querySelectorAll('.content-container');
      elements.forEach(el => {
          el.style.marginTop = '0px'; // Override the margin-top
      });

      // Optional: This function runs when the component unmounts
      return () => {
          elements.forEach(el => {
              el.style.marginTop = '170px'; // Revert the margin-top (if necessary)
          });
      };
  }, []); // Empty dependency array means this useEffect runs once when component mounts
    const handleSignOut = (id) => {
        // NZ Time
        const nzTime = new Date().toLocaleString('en-US', { timeZone: 'Pacific/Auckland', hour: '2-digit', minute: '2-digit', hour12: false });
        axios.post('https://trackmyaccess.site/api/updateSignOutTime', { id, signOutTime: nzTime })
            .then(response => {
                console.log(response.data);
                const user = data.find(item => item.id === id);
                setMessage(`Successfully signed out ${user ? user.fullName : 'the user'}!`);
                fetchData();  // Refreshing data after successful sign out

                // Start the countdown for redirect after showing success message
                setTimeout(() => {
                    window.location.href = '/'; // Redirect to root after 5 seconds
                }, 5000);
            })
            .catch(error => {
                console.error("Error updating sign out time", error);
            });
    }
    const navigate = useNavigate();
    const handleGoBack = () => {
    
      // Logic to go back
      navigate(-1); // If using useHistory hook
      //navigate(-1); // If using useNavigate hook from React Router v6
  };
    return (
        <div className={`center-contents ${fadeIn ? 'fade-in' : ''}`}>
          <div className="dashboard-container">
            <h1 className="h1">Sign Out</h1>
      
            {message && (
              <div className="message">
                {message}
              </div>
            )}
      
            {data.length > 0 ? (
              <div className="table-responsive page-centre">
                <table className="custom-table">
                  <thead>
                    <tr>
                    
                      <th>FULL NAME</th>
                      <th>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row) => (
                      <tr key={row.id}>
                        
                        <td>{row.fullName}</td>
                        <td>
                          <button className="sign-out-button" onClick={() => handleSignOut(row.id)}>SIGN OUT</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="no-users">
                No users to display.
              </div>
            )}
          </div>
          <div className="actions">

          <button type="button" onClick={handleGoBack}>Go Back</button>
                </div>
        </div>
      );
      
    
    
    
    
};

export default SignOut;
